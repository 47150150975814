<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_10.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Oficina de pintura de mão. Foto: Wan Campos / Jornal A Sirene, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Privação do brincar e elaborações do luto"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  O brincar é uma forma de expressão para as crianças e, para
                  que elaborem o luto vivido com todas as perdas decorrentes do
                  rompimento, é importante que se utilizem dessa linguagem
                  lúdica, típica da infância.
                </p>
                <p>
                  Na situação de luto coletivo desse desastre socioambiental
                  criminoso em que as pessoas tiveram perdas materiais e
                  afetivas imensas, as crianças passam ainda pela perda do mundo
                  em que estavam sendo socializadas. Um dos aspectos recorrentes
                  nos relatos é o temor pela própria vida e pela vida dos
                  familiares e amigos. Temor que não compunha o imaginário
                  pré-rompimento. Essas crianças e adolescentes viveram
                  situações de grande horror, como foram expressos nas falas que
                  seguem:
                </p>
                <p></p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            No dia foi horrível. Senti muito medo e temi pela vida de seus
            familiares (choro). Senti dor pela possibilidade de perda de meu avô
            [...]. Foi traumático, foi um susto e tive e tenho muito medo. Não
            tenho sossego para dormir lá. Sexo feminino, 14 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            No dia do rompimento da Barragem, quando a Emanuele estava sendo
            levada pela lama, foi muito triste ver ela pedindo socorro e os
            bombeiros dizendo que não podiam fazer nada. Sexo masculino, 15
            anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            Eu e minha família fomos pra casa, e não tivemos tempo de pegar
            nada, pois o rejeito da lama já estava na porta da casa. Precisei
            passar dentro da lama para fugir e quase desmaiei, precisei ser
            carregada. Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            Depois do rompimento da barragem fui até o rio e vi a devastação.
            Uns 8 dias depois da barragem ter se rompido, a gente entrou no rio
            para verificar se havia um corpo, alguma coisa. E encontrou um
            pedaço de uma coxa humana, do joelho até bem próximo da coxa. Sexo
            masculino, 14 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image5"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            [...] Eu estou preocupado e já conversei com minha avó [...]. Pedi
            pra vovó falar com o pessoal que vai construir o novo Bento que lá
            não pode ter barragem. Porque a gente escapou da morte uma vez, mas
            tenho muito medo de não ter a mesma sorte numa próxima.<sup>1</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_11.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Oficina de desenhos com as crianças. Foto: Wan Campos / Jornal A
            Sirene, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Privação do brincar e elaborações do luto"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  Assim, a criação de um espaço para um brincar autônomo, livre,
                  onde a criança se coloque como protagonista, é essencial para
                  as crianças. Ao serem deslocadas para viver na área urbana de
                  Mariana e, portanto, impedidas de brincar tal qual conhecem,
                  perdem também sua linguagem, seus recursos narrativos para
                  elaborarem o trauma, reencenarem, recontarem e, eventualmente,
                  virem a superar o evento traumático. Diante da dificuldade de
                  ensino dos conteúdos, a professora da quarta série da escola
                  municipal de Bento Rodrigues, reativada na sede de Mariana,
                  percebeu que aquelas crianças sentiam necessidade de
                  expressar-se. Assim, encontrou em um projeto de 2017, o
                  “Brincadeira todo dia era nossa alegria” que resultou em
                  livrinhos impressos com histórias criadas e desenhadas pelas
                  próprias crianças, uma forma de trabalhar o luto com seus
                  alunos: por meio da elaboração de narrativas e desenhos, as
                  crianças passaram a contar suas histórias.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: left" class="ma-0">
            Não tenho vizinhos para brincar, então não saio para brincar; na rua
            passa muito carro, então não dá para brincar. <br />Sexo feminino, 9
            anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            [...] andava de bicicleta e fazia caminhada com minha mãe e essas
            atividades me davam prazer [...] morando em Mariana, eu não consigo
            mais andar de bicicleta e nem caminhar com minha mãe. Eu acredito
            que essas atividades faziam bem pra minha cabeça. <br />Sexo
            feminino, 17 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            Depois que me mudei para Mariana, não tenho mais lazer. Em Ponte do
            Gama, podia frequentar a cachoeira e jogar bola com meus amigos.
            Depois que me mudei para Mariana, só consegui fazer amizade com dois
            colegas de escola, e mesmo assim não saio com eles, porque prefiro
            ficar em casa. <br />
            Sexo masculino, 15 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            Chegava [em Paracatu de Baixo] e já ia brincar. Brincava de birosca,
            belisca (Cinco Marias), subia nas árvores, nadava, pescava, brincava
            com os amigos, [...] brincava com meus primos: fazia piquenique,
            brincava de bola no campo, também no quintal ou na rua. Agora, fico
            em casa na maioria do tempo e brinco menos que antes, pois não tenho
            como fazer algumas coisas.”<br />
            Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image5"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            O que mais sinto falta é poder brincar na rua. Ficava na rua até
            tarde, meia-noite, e meus pais se preocupavam, porém, tinham
            segurança que a comunidade era amigável, pois todos se conheciam. Em
            Mariana não poderia fazer a mesma coisa, pois é perigoso.<br />
            Sexo feminino, 13 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Privação do brincar e elaborações do luto"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  No contexto de desastres, <sup>2</sup> o ato de brincar não
                  deve ser entendido como um recurso para acalmar, apaziguar a
                  dor ou trazer alegria a uma criança no sentido de buscar
                  silenciar certos sentimentos: o brincar é a principal
                  linguagem da criança, e a partir da construção dessa
                  linguagem, ela conhece e vive o mundo e se estrutura enquanto
                  sujeito. Atravessados pela emergência da situação, deixaram
                  bruscamente e em meio a situações drásticas, junto com as
                  bonecas, bolas e jogos,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name:
                        'conteudos_tematicos_crianca_adolescente_amadurecimento_precoce',
                    }"
                    >a própria infância</router-link
                  >.
                </p>
                <p style="text-align: center">
                  <strong
                    >Autoria: Pedro Paulo Gonçalves<sup>3</sup> e Laís Jabace
                    Maia<sup>4</sup></strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_12.png" />
          <img src="../../assets/conteudos_crianca_adolescente_13.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Crianças de Bento Rodrigues brincam em escola de Mariana. Foto: Wan
            Campos / Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> GONÇALVES, Flávio; NASCIMENTO, Brayan; SILVA, Allan.
              Bento: Um lugar de encantos. Série infantil “Bento: passado,
              presente e futuro”. Escola Municipal de Bento Rodrigues: Fundação
              Renova, 1ª ed. 2017.
            </p>
            <p>
              <sup>2</sup> BIZOTTO, Luciana Maciel; GOMES, Aline Regina; XAVIER,
              Pollyana Franfes. “Para as crianças, distração…”: sobre formas de
              se olhar a infância em contextos de desastres. Revista UFMG, Belo
              Horizonte, v. 27, n. 2, p. 492-517, mai./ago. 2020.
            </p>
            <p>
              <sup>3</sup> Mestre em Planejamento Urbano e Regional
              (IPPUR/UFRJ), Graduado em Sociologia (Unitau) e Relações
              Internacionais (PUC Minas). Assessor Técnico na ATI Mariana.
            </p>
            <p>
              <sup>4</sup> Cientista Social (UFMG), mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Desde 2020 é
              Coordenadora Operacional da Assessoria Técnica aos Atingidos e
              Atingidas pelo Rompimento da Barragem do Fundão em Mariana pela
              Cáritas Brasileira Regional Minas Gerais.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Crianças e Adolescentes",
          href: "conteudos_tematicos_crianca_adolescente",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image3 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
  .border__image4 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image5 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }

  .border__image5 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_01.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding-right: 5em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_02.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_03.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 2em 2em 0;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_04.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 5em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_05.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}
</style>
